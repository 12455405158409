<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="3" lg="3">
        <v-date v-model="dateStart"></v-date>
      </v-col>
      <v-col cols="12" sm="12" md="3" lg="3">
        <v-date v-model="dateEnd"></v-date>
      </v-col>
      <v-col cols="12" sm="12" md="3" lg="3">
        <v-btn class="mx-3" fab dark color="indigo" @click="Search">
          <v-icon dark>fa fa-search</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="3" lg="3">
        <v-btn class="mx-3" fab dark color="green" @click="Prints">
          <v-icon dark>fa fa-print</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="hbillsale"
          :items="dsbillsale.Array"
          :items-per-page="-1"
          class="elevation-1"
          @click:row="handleClick"
        >
          <template v-slot:body.append>
            <tr>
              <td class="red--text" colspan="2">
                <small>* คลิกที่แถว</small>
              </td>
              <td class="text-right">รวม</td>
              <td class="text-right">{{dsbillsale.Total}}</td>
              <td class="text-right">{{dsbillsale.Receive}}</td>
              <td class="text-right">{{dsbillsale.TotalReceive}}</td>
              <td class="text-right">{{dsbillsale.WidenTotal}}</td>
              <td class="text-right">{{dsbillsale.LendTotal}}</td>
              <td class="text-right">{{dsbillsale.ReceiveTotal}}</td>
              <td class="text-right">{{dsbillsale.SumStale}}</td>
              <td class="text-right">{{dsbillsale.SumTotal}}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      dateEnd: "",
      dateStart: "",
      hbillsale: [
        {
          text: "ระดับสมาชิก",
          align: "left",
          sortable: true,
          value: "UserLevelValue",
          width: 120,
        },
        {
          text: "ชื่อสมาชิก",
          align: "left",
          sortable: true,
          value: "UserName",
        },
        {
          text: "เบอร์โทรศัพท์",
          align: "left",
          sortable: true,
          value: "UserTel",
          width: 120,
        },
        {
          text: "ทั้งหมด",
          align: "right",
          sortable: true,
          value: "Total",
          width: 90,
        },
        {
          text: "จ่าย",
          align: "right",
          sortable: true,
          value: "Receive",
          width: 90,
        },
        {
          text: "ค้าง",
          align: "right",
          sortable: true,
          value: "Total_Receive",
          width: 90,
        },
        {
          text: "เบิก",
          align: "right",
          sortable: true,
          value: "WidenTotal",
          width: 90,
        },
        {
          text: "ยืม",
          align: "right",
          sortable: true,
          value: "LendTotal",
          width: 90,
        },
        {
          text: "คืน",
          align: "right",
          sortable: true,
          value: "ReceiveTotal",
          width: 90,
        },
        {
          text: "ค้าง",
          align: "right",
          sortable: true,
          value: "Stale",
          width: 90,
        },
        {
          text: "รวม",
          align: "right",
          sortable: true,
          value: "SumTotal",
          width: 90,
        },
      ],
      dsbillsale: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      let date = Date.now();
      date = new Date(date);

      let start =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      let end =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      this.Select(start, end);
    });
  },
  methods: {
    handleClick(v) {
      this.$router.push({
        name: "reportmembermember",
        query: {
          UserId: v.UserId,
          dateStart: this.dateStart,
          dateEnd: this.dateEnd,
        },
      });
    },
    Search() {
      this.Select(this.dateStart, this.dateEnd);
    },
    Select(start, end) {
      axios
        .post("/api/v1/select/SelectDateMember/user", {
          dateStart: start,
          dateEnd: end,
        })
        .then((res) => {
          if (res.status == 200) {
            this.dsbillsale = res.data;
          }
        });
    },
    Prints() {
      if (this.dsbillsale.Array.length <= 0) return;
      axios
        .post("/api/v1/Report/pdf", {
          hdtext: "รายงานสมาชิก",
          hd: this.hbillsale,
          ite: this.dsbillsale.Array,
          fot: [
            { text: "รวม", cols: 4 },
            { text: this.dsbillsale.Total, cols: 1 },
            { text: this.dsbillsale.Receive, cols: 1 },
            { text: this.dsbillsale.TotalReceive, cols: 1 },
            { text: this.dsbillsale.WidenTotal, cols: 1 },
            { text: this.dsbillsale.LendTotal, cols: 1 },
            { text: this.dsbillsale.ReceiveTotal, cols: 1 },
            { text: this.dsbillsale.SumStale, cols: 1 },
            { text: this.dsbillsale.SumTotal, cols: 1 },
          ],
        })
        .then((res) => {
          if (res.status == 200) {
            this.PrintJs({
              printable: res.data.base64,
              type: "pdf",
              base64: true,
            });
          }
        });
    },
  },
};
</script>
<style>
</style>